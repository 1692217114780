import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Button
} from 'reactstrap'
import TreeView from './modules/treeview'
import { DateRangePicker } from 'react-dates'
import { NotificationManager } from 'react-notifications'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'

import './sidebar.css'
import { confs } from '../../bootstrap/config'
import * as ActionTypes from '../../store'

class SideBar extends Component {
  constructor() {
    super()
    this.state = {
      activeTab: "surveys-tab",
      addUserVisible: false,
      newUserEmail: '',
      surveyTree: null,
      selectedSurveys: [],
      sidebarOpen: false
    }

    this.onUserAddSubmit = this.onUserAddSubmit.bind(this)
    this.onTreeHeadClick = this.onTreeHeadClick.bind(this)
    this.onTreeSurveyClick = this.onTreeSurveyClick.bind(this)
    this.onAddNewGroup = this.onAddNewGroup.bind(this)
    this.onDeleteSurveyTreeBranch = this.onDeleteSurveyTreeBranch.bind(this)
    this.saveSurveyTree = this.saveSurveyTree.bind(this)
  }
  
  componentDidMount() {
    this.setState({surveyTree: this.props.business.surveyTree, selectedSurveys: this.props.selectedSurveys || []})
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.business.surveyTree != this.props.business.surveyTree) {
      this.setState({surveyTree: this.props.business.surveyTree})  
    }
    if(typeof this.addUserInput !== 'undefined' && this.addUserInput !== null) {
      this.addUserInput.focus()
    }
    if(this.state.selectedSurveys !== this.props.selectedSurveys) {
      this.setState({selectedSurveys: this.props.selectedSurveys})
    }
  }

  handleDateChange(obj) {
    this.setState({
      startDate: obj.start,
      endDate: obj.end
    }, () => {
      if((obj.start !== null && obj.end !== null) || (obj.start === null && obj.end === null)) {
        this.props.handleDateChange(this.state.startDate, this.state.endDate)
      }
    })
  }

  handleTabChange(tab) {
    this.setState({activeTab: tab})
    this.props.handleTabChange(tab)
  }

  onUserAddSubmit(e) {
    e.preventDefault()

    if(this.validateEmail(this.state.newUserEmail)) {
      this.props.addBusinessUser({email: this.state.newUserEmail})
      this.setState({addUserVisible: false, newUserEmail: ''})
    } else {
      NotificationManager.error("Sähköpostiosoite väärässä muodossa.", "Ilmoitus", 2000)
    }
  }

  validateEmail(email) {
    const emailCheck = /[^@]+@[^\.]+\..+/;

    if(email !== '' && emailCheck.test(email)) {
      return true
    }
    return false
  }

  onTreeHeadClick(treeObj) {
    let selectedSurveys = this.state.selectedSurveys
    let groupHasAllSurveysSelected = true
    
    treeObj.surveys.map((survey) => {
      if(selectedSurveys.indexOf(survey.id) === -1) {
        groupHasAllSurveysSelected = false
      }
    })

    treeObj.surveys.map((survey) => {
      let index = selectedSurveys.indexOf(survey.id)

      if(groupHasAllSurveysSelected) {
        selectedSurveys.splice(index, 1)
      } else {
        if(index === -1) {
          selectedSurveys.push(survey.id)  
        }
      }
    })
    
    this.setState({selectedSurveys: selectedSurveys}, () => {
      this.props.makeTreeviewSelection(this.state.selectedSurveys)
      let searchArray = []

      selectedSurveys.map((id) => {
        searchArray.push({id: id})
      })
      this.props.updateSelectedSurveys(this.state.selectedSurveys)
      // this.props.getSurveyStatistics(this.props.business._id, null, searchArray)
    })
  }

  onTreeSurveyClick(surveyObj) {
    let selectedSurveys = this.state.selectedSurveys
    let index = selectedSurveys.indexOf(surveyObj.id)
    
    if(index === -1) {
      selectedSurveys.push(surveyObj.id)
    } else {
      selectedSurveys.splice(index, 1)
    }
    
    this.setState({
      selectedSurveys: selectedSurveys
    }, () => {
      this.props.makeTreeviewSelection(this.state.selectedSurveys)
      
      this.props.updateSelectedSurveys(this.state.selectedSurveys)
      // this.props.getSurveyStatistics(this.props.business._id, null, searchArray)
    })
  }

  onSurveyTreeChange(e, index) {
    let newSurveyTree = [
      ...this.state.surveyTree,
    ]
    newSurveyTree[index].title = e.target.value
    
    this.setState({surveyTree: newSurveyTree})
  }

  onAddNewGroup() {
    let newGroup = {
      title: "Uusi ryhmä",
      surveys: []
    }
    if(this.state.surveyTree && this.state.surveyTree.length > 0) {
      this.setState({
        surveyTree: [
          ...this.state.surveyTree,
          newGroup
        ]
      }, () => {
        this.saveSurveyTree()
      })    
    } else {
      this.setState({
        surveyTree: [
          newGroup
        ]
      }, () => {
        this.saveSurveyTree()
      })
    }
    
  }

  onDeleteSurveyTreeBranch(index) {
    let surveyTree = [...this.state.surveyTree]
    if(surveyTree[index].surveys.length > 0) {
      let groupSurveys =  surveyTree[index].surveys

      let kohdistamattomatGroup = surveyTree.filter(branch => branch.title === 'Kohdistamattomat')
      
      if(kohdistamattomatGroup.length > 0) {
        const kohdistamattomatIndex = surveyTree.indexOf(kohdistamattomatGroup[0])
        
        if(typeof kohdistamattomatGroup[0].surveys !== 'undefined') {
          kohdistamattomatGroup[0].surveys = [
            ...kohdistamattomatGroup[0].surveys,
            ...groupSurveys
          ]
        } else {
          kohdistamattomatGroup[0].surveys = groupSurveys
        }
        surveyTree[kohdistamattomatIndex] = kohdistamattomatGroup[0]
      } else {
        surveyTree.push({title: 'Kohdistamattomat', surveys: groupSurveys})
      }
    }
    surveyTree.splice(index, 1)
    this.setState({surveyTree: surveyTree}, () => this.saveSurveyTree())
  }

  saveSurveyTree() {
    this.props.saveSurveyTree(this.props.business._id, this.state.surveyTree)
  }

  render() {
    return (
      <div className={'sidebar' + (this.state.sidebarOpen ? '' : ' closed')}>
        <Row>
          <Col className="text-center" style={{borderBottom: "1px solid #FFF"}} onClick={() => this.setState(prevState => ({sidebarOpen: !prevState.sidebarOpen}))}>
            <div className="material-icons sidebar-toggler-icon">menu</div>
          </Col>
        </Row>
        {/* <i className="material-icons" id="close-sidebar-icon" onClick={this.props.closeSideBar}>clear</i> */}
        {this.state.sidebarOpen ? (
          <Nav tabs fill id="sidebar-nav">
            <NavItem className="w-50 text-center">
              <NavLink onClick={(e) => this.handleTabChange(e.target.id)} className={"side-nav-item" + (this.state.activeTab === "surveys-tab" ? " active" : "")} id="surveys-tab">Kartoitukset</NavLink>
            </NavItem>
            <NavItem className="w-50 text-center">
              <NavLink onClick={(e) => this.handleTabChange(e.target.id)} className={"side-nav-item" + (this.state.activeTab === "admin-tab" ? " active" : "")} id="admin-tab">Hallinta</NavLink>
            </NavItem>
          </Nav>
        ) : (
          <Nav tabs fill id="sidebar-nav">
            <NavItem>
              <NavLink onClick={(e) => this.handleTabChange(e.target.id)} className={"sidebar-icon-tab material-icons" + (this.state.activeTab === "surveys-tab" ? " active" : "")} id="surveys-tab">blur_linear</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={(e) => this.handleTabChange(e.target.id)} className={"sidebar-icon-tab material-icons" + (this.state.activeTab === "admin-tab" ? " active" : "")} id="admin-tab">people</NavLink>
            </NavItem>
          </Nav>
        )}
        
        {this.state.sidebarOpen &&
          <Container id="sidebar-container" fluid>
            
            {this.state.activeTab === "surveys-tab" ? (
              <span>
                <Row>
                  <Col md={{size:12}}>
                    <h2 className="sidebar-content-title">{this.props.business.businessName}</h2>
                  </Col>
                </Row>

                <Row>
                  <TreeView onTreeHeadClick={this.onTreeHeadClick} onTreeSurveyClick={this.onTreeSurveyClick} selectedSurveys={this.state.selectedSurveys} />
                </Row>
                
                <Row>
                  <Col md={{size:12}} className="mt-5">
                    <p style={{marginBottom:"0"}}><small>Raportissa näytettävä aikaväli</small></p>
                    <DateRangePicker 
                      startDate={this.state.startDate}
                      startDateId="startDate"
                      endDate={this.state.endDate}
                      endDateId="endDate"
                      onDatesChange={({ startDate, endDate }) => this.handleDateChange({ start: startDate, end: endDate })}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      startDatePlaceholderText="Alku"
                      endDatePlaceholderText="Loppu"
                      displayFormat="DD.MM.YYYY"
                      firstDayOfWeek={1}
                      isOutsideRange={() => false}
                      hideKeyboardShortcutsPanel={true}
                      appendToBody
                      block
                      showClearDates
                    />
                  </Col>
                </Row>
                <Row className="bottom-row text-center">
                  <Col md={{size:12}}>
                    <div className="logo-container" style={ this.props.business.logo && {backgroundImage: `url(${confs.host}/api/business/getLogo/${this.props.business._id}`}}></div>
                  </Col>
                </Row>
              </span>
            ) : (
              <Row>
                <Col md={{size:12}}>
                  <Row>
                    <Col md={{size: 10}}>
                      <h2 className="sidebar-content-title">Käyttäjät</h2>
                    </Col>
                    <Col md={{size: 2}}>
                      <i className="material-icons user-add-button" tabIndex="-1" onClick={() => this.setState(prevState => ({addUserVisible: !prevState.addUserVisible}))}>person_add</i>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{size: 12}}>
                      <Scrollbars renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                        style={{width: "100%", height: "15em", marginBottom: "1em"}}
                      >
                      {this.props.users && this.props.users.map((user, index) => {
                        return <Row key={index}>
                          <Col md={{size: 12}}>
                            <p className="user-row">{user.email}</p>
                          </Col>
                        </Row>
                      })}
                      </Scrollbars>
                      {this.state.addUserVisible &&
                        <Form id="add-user-form" onSubmit={this.onUserAddSubmit}>
                          <h4 className="sidebar-content-title">Kutsu uusi käyttäjä</h4>
                          <FormGroup>
                            <input ref={(input) => {this.addUserInput = input }} onChange={(e) => this.setState({newUserEmail: e.target.value})} className="user-add-input form-control" type="text" name="user-email" placeholder="Käyttäjän sähköpostiosoite" value={this.state.newUserEmail} />
                          </FormGroup>
                          <Button type="submit" style={{width: "100%"}}>Kutsu</Button>
                        </Form>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{size: 12}} style={{marginTop: "2em"}}>
                      <h4 className="sidebar-content-title">Kartoitusryhmät</h4>
                      <Row>
                        <Col md={{size: 12}}>
                          {this.state.surveyTree && this.state.surveyTree.map((treeObj, index) => {

                            return treeObj.title !== "Kohdistamattomat" && <InputGroup className="surveytree-input-group" key={index}>
                              <Input className="business-input surveytree-input" type="text" value={treeObj.title} onChange={(e) => this.onSurveyTreeChange(e, index)} onBlur={() => this.saveSurveyTree()} />
                              <InputGroupAddon addonType="append"><i className="material-icons" style={{cursor: "pointer"}} onClick={() => this.onDeleteSurveyTreeBranch(index)}>delete</i></InputGroupAddon>
                            </InputGroup>

                          })}
                          <Button onClick={this.onAddNewGroup} style={{width: "100%"}}>Lisää uusi ryhmä</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Container>
        }
      </div>
    )
  }
  
}

const mapDispatchToProps = dispatch => {
  return {
    makeTreeviewSelection: (params) => dispatch(ActionTypes.makeSurveytreeSelection(params))
  }
}

export default connect(null, mapDispatchToProps)(SideBar)