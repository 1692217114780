import React from 'react'
import { 
  EditorState, 
  ContentState, 
  convertFromRaw, 
  convertToRaw,
  RichUtils
 } from 'draft-js'
import Editor from 'draft-js-plugins-editor'

/** Draft-js plugins */
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin'
import 'draft-js-inline-toolbar-plugin/lib/plugin.css' 

import createLinkPlugin from 'draft-js-anchor-plugin'
import 'draft-js-anchor-plugin/lib/plugin.css'

import createEmojiPlugin from 'draft-js-emoji-plugin';
import 'draft-js-emoji-plugin/lib/plugin.css'

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineThreeButton,
} from 'draft-js-buttons'

import './texteditor.css'

class TextEditor extends React.Component {
  constructor() {
    super()

    this.state = {
      editorState: EditorState.createEmpty(),
      editorReadOnly: false
    }

    this.editor = React.createRef()

    this.inlineToolbarPlugin = createInlineToolbarPlugin()
    const { InlineToolbar } = this.inlineToolbarPlugin

    this.linkPlugin = createLinkPlugin({
      placeholder: 'Linkin osoite',
      linkTarget: '_blank'
    })

    this.emojiPlugin = createEmojiPlugin();
    const { EmojiSuggestions, EmojiSelect } = this.emojiPlugin;

    this.plugins = [
      this.inlineToolbarPlugin, this.linkPlugin,
      this.emojiPlugin,
    ]

    this.components = {
      InlineToolbar,
      EmojiSuggestions,
      EmojiSelect
    }
  }

  componentWillMount() {
    const textContent = this.props.content
    let contentState = ''
    
    if(typeof textContent === 'string') {
      contentState = ContentState.createFromText(textContent)
    } else {
      contentState = convertFromRaw(textContent)
    }
    this.setState({
      editorState: EditorState.createWithContent(contentState), 
      editorReadOnly: this.props.readOnly || false,
      additionalClass: this.props.additionalClass || ""
    })
  }

  componentDidUpdate(prevProps) {    
    if(prevProps.editorId !== this.props.editorId) {
      const textContent = this.props.content
      let contentState = ''
      
      if(typeof textContent === 'string') {
        contentState = ContentState.createFromText(textContent)
      } else {
        contentState = convertFromRaw(textContent)
      }

      this.setState({
        editorState: EditorState.createWithContent(contentState), 
        editorReadOnly: this.props.readOnly || false,
        additionalClass: this.props.additionalClass || ""
      })
    }
  }

  onChange = (editorState) => {
    this.setState({editorState})
    if(typeof this.props.index !== 'undefined') {
      this.props.onTextChange(convertToRaw(editorState.getCurrentContent()), this.props.index)
    } else {
      this.props.onTextChange(convertToRaw(editorState.getCurrentContent()))
    }
  }

  onURLChange = (e) => {
    this.setState({urlValue: e.target.value})
  }

  focus = () => {
    this.editor.current.focus()
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  render() {
    const { editorReadOnly } = this.state
    const { InlineToolbar, EmojiSuggestions, EmojiSelect } = this.components

    return(
      <div onClick={this.focus} className={"texteditor-container " + (this.state.editorReadOnly ? "readonly " : "") + this.state.additionalClass}>
        <Editor 
          editorState={this.state.editorState} 
          onChange={this.onChange} 
          handleKeyCommand={this.handleKeyCommand}
          readOnly={editorReadOnly}
          ref={this.editor}
          plugins={this.plugins}
          className="EYYY"
        />
        <EmojiSuggestions />
        <InlineToolbar>
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps) => (
              <React.Fragment>
                <HeadlineThreeButton {...externalProps} />
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <this.linkPlugin.LinkButton {...externalProps} />
              </React.Fragment>
            )
          }
          </InlineToolbar>
          
      </div>
    )
  }

}

export default TextEditor