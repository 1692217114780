import * as ActionTypes from '../actions/loginActions/actionTypes';

const initialState = {
  loggedUser: null
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        loggedUser: action.payload
      }
    case ActionTypes.LOGOUT:
      return {
        ...state,
        loggedUser: null
      }
    case ActionTypes.IS_AUTHENTICATED:
      return {
        ...state,
        loggedUser: {
          ...state.loggedUser,
          ...action.payload
        }
      }
    

    default:
      return state;
  }

}
