import React, { Component } from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Table} from 'reactstrap'
import moment from 'moment'

export default class FreetextAnswersModal extends Component {
  constructor() {
    super()
  }


  /**
   * css in App.css
   */
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggleModal()} centered={true} className="freetext-answers-modal">
        <ModalHeader toggle={this.props.toggle}>Vapaatekstivastaukset</ModalHeader>
        <ModalBody>
          {Object.keys(this.props.freetextAnswers).map((key, index) => {
            let answerStack = this.props.freetextAnswers[key]

            return (
              <div key={index}>
                <Row>
                  <Col>
                    <h5><b>{answerStack.question}</b></h5>
                  </Col>
                </Row>
                <Table>
                  <tbody>
                  {answerStack.answers && answerStack.answers.map((answer, index) => {
                    return (    
                      <tr key={index}>
                        <td>{moment(new Date(answer.time)).format('L LT')}</td>
                        <td>{answer.text}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </div>
            )
          })}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.props.toggleModal()}>Sulje</Button>
        </ModalFooter>
      </Modal>
    )
  }
}