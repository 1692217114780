import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as ActionTypes from '../../store'
import { confs } from '../../bootstrap/config'

class StagingArea extends Component {

  componentWillMount() {
    if(typeof this.props.match.params.token !== 'undefined' && this.props.match.params.token !== null) {
      let token = this.props.match.params.token || "";
      
      if(token !== "") {
        let params = {
          token: token
        }
        
        this.props.checkLogin(params);
      }
    } else {
      const token = window.sessionStorage.getItem("business-token")

      if(token !== null) {
        let params = {
          token: token
        }
        
        this.props.authenticateUser(params);
      } else {
        return window.location.href = confs.frontpageUrl + 'login'
      }
    }
  }

  render() {
    return (
      <div style={{padding: "30px"}}>
        <h1>Kirjaudutaan sisään...</h1>

        {/* redirects */}
        {this.props.loggedUser && this.props.loggedUser.isAuthenticated && <Redirect to='/home' />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    loggedUser: state.login.loggedUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkLogin: (params) => dispatch(ActionTypes.checkLogin(params)),
    authenticateUser: (params) => dispatch(ActionTypes.authenticateUser(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StagingArea)