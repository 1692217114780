import { NotificationManager } from 'react-notifications'
import * as ActionTypes from './actionTypes'
import { confs } from '../../../bootstrap/config'
import * as AllActions from '../../index'

const getStatistics = statsObj => {
  return {
    type: ActionTypes.GET_STATISTICS,
    payload: statsObj
  }
}

const checkResponseForAuth = (data) => {
  if(typeof data.authenticated !== 'undefined' && !data.authenticated) {
    AllActions.doLogout()
    window.location.href = confs.frontpageUrl + 'login'
    return false
  } else {
    return true
  }
}

export const getSurveyStatistics = (businessId, dateRange, surveyIds) => dispatch => {

  if(surveyIds.length > 0) {
    const nrOfSurveys = surveyIds.length

    const postData = {
      id: businessId,
      dateRange: dateRange,
      sentSurveyIds: surveyIds,
      token: window.sessionStorage.getItem("business-token")
    }
    
    fetch(confs.url + 'getSurveyStatistics', {
      method: 'POST',
      body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then(data => {
      if(checkResponseForAuth(data)) {
        if(data.length > 0) {
          //MIKS TÄÄ DISPATCH KESTÄÄÄ ??
          dispatch(getStatistics(data))
          NotificationManager.success(`Raportissa mukana ${nrOfSurveys} kartoitusta`, 'Tiedot päivitetty', 3000)
        } else {
          if(dateRange !== null) {
            NotificationManager.error('Aikavälillä ei löytynyt vastauksia', 'Virhe', 2000);
          }
        }
      }
    })
  } 
  else {
    dispatch(getStatistics(surveyIds))
  }
}

export const printReport = payload => {
  let jwt = window.sessionStorage.getItem("business-token");

  let params = {
    reports: [...payload],
    jwtToken: jwt
  }

  return dispatch => {
    fetch(confs.host + '/api/get/printReport', {
      method: 'POST',
      body: JSON.stringify(params)
    }).then(function(response) {
      return response.blob()
    }).then(function(res) {
      if(checkResponseForAuth(res)) {
        var data = new Blob([res], {type: 'application/pdf'})
        var csvURL = window.URL.createObjectURL(data)
        let tempLink = document.createElement('a')
        document.body.appendChild(tempLink)
        tempLink.href = csvURL
        let filename = 'Qartoitus-'+ Date.now() + '.pdf'
        tempLink.setAttribute('download', filename.replace(' ','-').trim())
        tempLink.click()
      }
    })
  }
}