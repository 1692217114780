import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Table} from 'reactstrap';
import moment from 'moment';

const ViewTextModal = props => {
  return (
    <Modal isOpen={props.isOpen} centered={true} size="lg">
      <ModalHeader>Lisätyt jatkot</ModalHeader>
      <ModalBody>
        <p>{props.text}</p>
      </ModalBody>
      <ModalFooter>
          <Button onClick={() => props.toggleModal()}>Valmis</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewTextModal;