import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Treenode from './treenode'
import { Scrollbars } from 'react-custom-scrollbars'

export default class Treehead extends React.Component {

  render() {
    return (
      <div>
         <Draggable index={this.props.index} draggableId={this.props.id} isDragDisabled={this.props.isDragDisabled} type="header">
        {provided => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <span>
              <div onClick={() => {this.props.onTreeHeadClick && this.props.onTreeHeadClick(this.props.surveyTree)}} data-id={this.props.surveyTree.id} className={"tree-header " + this.props.class}> {this.props.surveyTree.title} <i className="material-icons" style={{float:"right"}}>open_with</i></div>
            </span>
            
            <Droppable droppableId={this.props.id} type="node">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef} 
                  className={snapshot.isDraggingOver ? 'dragging-over tree-child' : 'tree-child'}
                  {...provided.droppableProps}
                >
                <Scrollbars renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                  autoHeight
                  autoHeightMin={40}
                  autoHeightMax={300}
                  style={{width: "100%", marginBottom: "1em"}}
                >
                  {this.props.surveyTree.surveys.map((obj, index) => {
                    return <Treenode 
                      onTreeSurveyClick={this.props.onTreeSurveyClick} 
                      key={index} 
                      index={index} 
                      id={obj.id} 
                      title={obj.title} 
                      selectedSurveys={this.props.selectedSurveys}
                    />
                  })}
                  {provided.placeholder}
                </Scrollbars>
                </div>
              )}
            </Droppable>
          
          </div>
        )}
      </Draggable>
        
      </div>
    )
  }
}
