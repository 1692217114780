import React from 'react'
import { NavLink } from "react-router-dom"
import {
  NavItem
} from 'reactstrap'
import './navbar.css'
import {connect} from 'react-redux';

import * as ActionTypes from '../../store';
import { confs } from '../../bootstrap/config'

class NavigationBar extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      requestsOpen: false
      }
    }


handleClick = () => {
  this.setState({ requestsOpen: !this.state.requestsOpen });
}

requestResponse = (request, answer) => {
  let params = {
    ...request,
    bid: this.props.business._id,
    user: this.props.loggedUser.email,
    agreement: answer
  }
  this.props.collaborationResponse(params);

}

onLogout() {
  window.sessionStorage.removeItem("business-token")
  return window.location.href = confs.frontpageUrl + 'login'
}


render() {
  return (
    <span>
      <div className="navbar navbar-expand-md">
        <div className="navbar-brand">
          <NavLink to='/'><div className="qibbie-logo"></div></NavLink>
        </div>

        <button className="navbar-toggler" type="button" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <i className="material-icons">menu</i>
        </button>

        <div className="collapse navbar-collapse" id="navbarMenu">
          <ul className="navbar-nav ml-auto">
            {this.props.links && this.props.links.map((broLink, i) => {
              return <li key={i} className="nav-item"><NavLink className="nav-link" to={broLink.to} activeClassName='active-nav'>{broLink.text}</NavLink></li>
            })}
            {this.props.notifications && this.props.notifications.pendingRequests && Object.keys(this.props.notifications.pendingRequests).length > 0 ?
              <span>
                {this.props.notifications.pendingRequests || this.props.notifications.messages || this.props.notifications.notifications ?
                <span>
                  <div onClick={() => this.handleClick()} className="navbar-nav request-icon nav-notification-bell" style={{background: '#ED5652'}}>
                    <i className="material-icons" style={{color: 'white', fontSize: '29px'}}>notifications_none</i>
                  </div>
                </span>
                :
                  null
                }
              </span>
              :
              <div onClick={() => this.handleClick()} className="navbar-nav border nav-notification-bell" style={{background: 'white', cursor: 'pointer'}}>
              <i className="material-icons" style={{color: '#404040', fontSize: '29px'}}>notifications_none</i>
              </div>
            }
            <NavItem className="nav-item ml-auto"><p className="nav-link logout-button" onClick={this.onLogout}>Kirjaudu ulos</p></NavItem>
          </ul>
        </div>

      </div>
        {this.props.notifications && this.state.requestsOpen ?
          <div style={{position: 'absolute', right: '0', top: '70px', width: '400px', marginRight: '15px'}}>
            {this.props.notifications.pendingRequests && this.props.notifications.pendingRequests.map((request, i) => {
              return <div className="requests" key={i} style={{background: 'white', marginBottom: '5px', padding: '1rem', marginBottom: '10px'}}>
               <div>Olet saanut yhteistyöpyynnön: {request.businessName} </div>
                <div style={{textAlign: 'center'}}>
                  <span onClick={() => this.requestResponse(request, true)} className="px-2" style={{color: '#B5E413', cursor: 'pointer'}}> Hyväksy </span>
                  <span onClick={() => this.requestResponse(request, false)} className="px-2" style={{color: '#ED5652', cursor: 'pointer'}}> Hylkää </span>
                </div>
              </div>
            })}

            {this.props.notifications.notifications && this.props.notifications.notifications.map((notification, i) => {
              return <div className="requests" key={i} style={{background: 'white', marginBottom: '5px', padding: '1rem', marginBottom: '10px'}}>
                <div>{notification.message}</div>
                <div style={{textAlign: 'center'}}><span style={{cursor: 'pointer'}}>Ok</span></div>
              </div>
            })}

            {this.props.notifications.messages && this.props.notifications.messages.map((message, i) => {
              return <div className="requests" key={i} style={{background: 'white', marginBottom: '5px', padding: '1rem', marginBottom: '10px'}}>
                <div>{message.message}</div>
              </div>
            })}

          </div>
        :  
          <span></span>
        }
    </span>
  )
}
}
const mapDispatchToProps = dispatch => {
  return {
    collaborationResponse: (params) => dispatch(ActionTypes.collaborationResponse(params))
  }
}
const mapStateToProps = state => {
  return {
    notifications: state.business.notifications,
    business: state.business.business,
    loggedUser: state.login.loggedUser
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);