import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Table} from 'reactstrap';
import moment from 'moment';

const ViewModal = props => {
  return (
    <Modal isOpen={props.isOpen} centered={true} size="lg">
      <ModalHeader>Lisätyt jatkot</ModalHeader>
      <ModalBody>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Lisätty</th>
            <th>Lisääjä</th>
            <th>Koodi</th>
            <th>Tiedot</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(props.notes).length > 0 &&
            props.notes.map((note, index) => {
              return (<tr key={index}>
                <th scope="row">
                    {index}
                </th>
                <td>{moment(note.created).format('DD.MM.YYYY HH:mm:ss')}</td>
                <td>{note.createdBy}</td>
                <td>{note.code}</td>
                <td style={{textAlign:"center"}}><i className="material-icons notes-icon" onClick={() => props.viewNoteText(note.notes)}>menu</i></td>
              </tr>)
            })
          }
        </tbody>
      </Table>
      </ModalBody>
      <ModalFooter>
          <Button onClick={() => props.toggleNoteModal()}>Valmis</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ViewModal;