import React, { Component } from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import NavigationBar from './Components/NavigationBar';

import Home from './Components/Home';
import StagingArea from './Components/StagingArea/stagingArea'

class App extends Component {
  render() {
    return (
      <span>
        <header>
          <NavigationBar />
        </header>
        
        <NotificationContainer />
        <Switch>
          <Route exact path='/home' component={Home} />
          <Route exact path="/:token" component={StagingArea} />
          <Route component={StagingArea} />
        </Switch>
        
        <footer>

        </footer>
      </span>
    );
  }
}

export default App;
