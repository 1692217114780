import React from 'react';
import {Row, Col} from 'reactstrap';
import ComparisonBar from './comparisonBar';
import TextEditor from '../../../Utilities/TextEditor/texteditor'

const BarReport = props => {
    return (
        props.sentSurveys.map((survey, ssIndex) => {                
            const surveyColor = survey.surveyColor;
            
            return typeof survey.surveyTotalStatistics !== 'undefined' && 
                <Row className="survey-main-row" key={ssIndex}>
                    <div className="ball-container">
                    <div className="large-score-ball" style={{backgroundColor: surveyColor, boxShadow: "0 0 0 2pt " + surveyColor}}>
                        {survey.surveyTotalStatistics.avg}
                    </div>
                    </div>
                    <Col md={{size: 11}} className="survey-title-container super-border-left" style={{borderColor: surveyColor}}>
                        <Row className="survey-title-row">
                            <Col md={{size: 12}}>
                                <h2>{survey.title}</h2>
                                <hr />
                                {/* <p>{survey.text}</p> */}
                                <TextEditor editorId={survey._id} content={survey.text} onTextChange={() => {return}} readOnly={true} />
                            </Col>
                        </Row>
                        {survey.questionGroups.map((group, gIndex) => {
                                        
                            const groupColor = group.groupColor;
                            return typeof group.groupTotalStatistics !== 'undefined' && 
                            <Row className="questiongroup-main-row" key={gIndex}>
                                <div className="ball-container">
                                    <div className="medium-score-barball" style={{backgroundColor: groupColor, boxShadow: "0 0 0 2pt " + groupColor}}>{group.groupTotalStatistics.avg}</div>
                                </div>
                                <Col md={{size: 11}} className={gIndex < (survey.questionGroups.length - 1) ? "questiongroup-title-container-comparison super-border-left" : "questiongroup-title-container-comparison"} style={{borderColor: groupColor}}>
                                    <Row className="questiongroup-title-row" style={{marginBottom: '3.5em'}}>
                                        <Col md={{size: 12}}>
                                            <h2>{group.title}</h2>
                                            <ComparisonBar 
                                            feedbacks={group.feedbacks} 
                                            avg={group.groupTotalStatistics.avg} 
                                            scores={group.groupTotalStatistics.scores} 
                                            ballColor={groupColor}/>
                                        </Col>
                                    </Row>
                                    {/*<Row>
                                        
                                            {group.questions.map((question, qIndex) => {
                                                if(question.answerStatistics) {
                                                    const questionColor = question.questionColor;
                                                    return <Col md={{size: 12}} className="question-title-container-comparison" key={qIndex}>
                                                                <Row>
                                                                    <Col className="col-xl-auto" md="3">
                                                                    <div className="ball-container-comparison">
                                                                        <div className="small-score-barball" style={{backgroundColor: questionColor, boxShadow: "0 0 0 2pt " + questionColor}}>{question.answerStatistics.avg}</div>
                                                                    </div>
                                                                    </Col>
                                                                    <Col className="col-xl" md="9">        
                                                                    <h4>{question.text}</h4>
                                                                        <ComparisonBar 
                                                                        feedbacks={group.feedbacks} 
                                                                        avg={question.answerStatistics.avg} 
                                                                        scores={question.answerStatistics.scores} 
                                                                        ballColor={questionColor}/>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                }
                                            })}
                                        </Row>*/}
                                </Col> 
                            </Row>
                        })}
                    </Col>
                </Row>
        })
    )
}

export default BarReport;