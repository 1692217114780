import * as ActionTypes from './actionTypes'
import { confs } from '../../../bootstrap/config'
import { NotificationManager } from 'react-notifications'
import * as AllActions from '../../index'

const checkResponseForAuth = (data) => {
  if(typeof data.authenticated !== 'undefined' && !data.authenticated) {
    AllActions.doLogout()
    window.location.href = confs.frontpageUrl + 'login'
    return false
  } else {
    return true
  }
}

const editBusinessToState = params => {
  return {
    type: ActionTypes.EDIT_BUSINESS,
    payload: params
  }
}

export const editBusiness = (data) => dispatch => {
  data.token = window.sessionStorage.getItem("business-token")
  return fetch(confs.url + 'editBusiness', {
    method: 'POST',
    body: JSON.stringify(data)
  })
  .then(res => res.json())
  .then(data => {
    if(data) {
      if(checkResponseForAuth(data)) {
        NotificationManager.success("Tietojen tallentaminen onnistui", "Hienoa!", 2000)
        dispatch(editBusinessToState(data))
      }
    } else {
      NotificationManager.error("Tietojen tallentaminen epäonnistui", "Jokin meni pieleen", 2000)
    }
    
  })
}

const getBusinessToState = params => {
  return {
    type: ActionTypes.GET_BUSINESS,
    payload: params
  }
}

export const getBusiness = (business) => dispatch => {
  business.token = window.sessionStorage.getItem("business-token")
  return fetch(confs.url + 'getBusiness', {
    method: 'POST',
    body: JSON.stringify(business)
  })
  .then(res => res.json())
  .then(data => {
    if(checkResponseForAuth(data)) {
      dispatch(getBusinessToState(data))
    }
  })
}

const saveLogo = params => {
  return {
    type: ActionTypes.EDIT_LOGO,
    payload: params
  }
}

export const uploadLogo = (data) => dispatch => {
  data.token = window.sessionStorage.getItem("business-token")

  return fetch(confs.url + 'uploadLogo', {
    method: 'POST',
    body: data
  })
  .then(res => res.json())
  .then(response => {
    if(checkResponseForAuth(response)) {
      if(response.success) {
        dispatch(saveLogo(response.logo))
        NotificationManager.success("Logon tallentaminen onnistui", "Hienoa!", 2000)
      } else {
        switch(response.error) {
          case "SIZE":
            NotificationManager.error('Tiedoston koko täytyy olla vähemmän kuin 16MB', 'Logon tallennus epäonnistui', 2000);
            return;
          case "TYPE":
            NotificationManager.error('Tiedoston täytyy olla kuvatiedosto', 'Logon tallennus epäonnistui', 2000);
            return;
          default:
            NotificationManager.error('Jokin meni pieleen :(', 'Logon tallennus epäonnistui', 2000);
            return;
        }
        
      }
    }
  })
}

const saveBusinessUser = params => {
  return {
    type: ActionTypes.ADD_BUSINESSUSER,
    payload: params
  }
}

export const addBusinessUser = (data) => dispatch => {
  data.token = window.sessionStorage.getItem("business-token")

  return fetch(confs.url + 'addBusinessUser', {
    method: 'POST',
    body: JSON.stringify(data)
  })
  .then(res => res.json())
  .then(response => {
    if(checkResponseForAuth(response)) {
      if(response._id) {
        NotificationManager.success("Kutsu lähetetty", "Ilmoitus", 2000)
        dispatch(saveBusinessUser(response))
      } else {
        NotificationManager.error("Kutsun lähetys epäonnistui. Sähköpostiosoiteella on jo käyttäjä.", "Ilmoitus", 2000)
      }
    }
  })
}

const reorderToState = surveyTree => {
  return {
    type: ActionTypes.REORDER_SURVEYTREE,
    payload: surveyTree
  } 
}

export const reorderSurveytree = (businessId, surveyTree) => dispatch => {
  dispatch(reorderToState(surveyTree))
  let postData = {
    businessId: businessId,
    surveyTree: surveyTree,
    token: window.sessionStorage.getItem("business-token")
  }
  fetch(confs.url + 'reorderSurveys', {
    method: 'POST',
    body: JSON.stringify(postData)
  })
  .then(res => res.json())
  .then(data => {
    if(checkResponseForAuth(data)) {
      if(!data.success) {
        NotificationManager.error('Tallennus epäonnistui', 'Virhe', 2000);
      }
    }
  }) 
}

export const getNotifications = (business) => dispatch => {
  business.token = window.sessionStorage.getItem("business-token")

  return fetch(confs.url + 'getNotifications', {
      method: 'POST',
      body: JSON.stringify(business)
  })
  .then(res => res.json()
  .then(data => {
    if(checkResponseForAuth(data)) {
      dispatch({
        type: ActionTypes.GET_NOTIFICATIONS,
        payload: data
      })
    }
  }))
}

export const collaborationResponse = (params) => dispatch => {
let postData = {
  ...params,
  token: window.sessionStorage.getItem("business-token")
}
fetch(confs.url + 'notificationResponse', {
  method: 'POST',
  body: JSON.stringify(postData)
})
.then(res => res.json())
.then(data => {
  if(checkResponseForAuth(data)) {
    dispatch ({
      type: ActionTypes.GET_NOTIFICATIONS,
      payload: data
    })}
  }
)}

export const makeSurveytreeSelection = (params) => dispatch => {
  dispatch({
    type: ActionTypes.SURVEYTREE_SELECTION,
    payload: params
  })
}
