import * as ActionTypes from '../actions/businessActions/actionTypes';

const initialState = {
  email: "miika.niemi@qibbie.com",
  notifications: {},
  business: {
    businessName: "",
    address: "",
    businessID: "",
    surveyTree: [],
    selectedSurveys: []
  }
}

export default function(state = initialState, action) {
  switch(action.type) {

    case ActionTypes.GET_BUSINESS:
    return {
      ...state, 
      business: action.payload.business,
      businessUsers: action.payload.users
    }

    case ActionTypes.GET_NOTIFICATIONS:
    return{
      ...state,
      notifications: action.payload
    }
    case ActionTypes.COLLABORATION_RESPONSE:
      return {
        ...state,
        ...action.payload
      }

    case ActionTypes.EDIT_BUSINESS:
      return {
        ...state,
        business: {
          ...state.business,
          ...action.payload
        }
      }

    case ActionTypes.EDIT_LOGO:
      return {
        ...state,
        business: {
          ...state.business,
          logo: {
            ...action.payload
          }
        }
      }
    
    case ActionTypes.ADD_BUSINESSUSER:
      return {
        ...state,
        businessUsers: [
          ...state.businessUsers,
          action.payload
        ]
      }

    case ActionTypes.REORDER_SURVEYTREE:
      return {
        ...state,
        business: {
          ...state.business,
          surveyTree: action.payload
        }
      }

    case ActionTypes.SURVEYTREE_SELECTION:
      return {
        ...state,
        business: {
          ...state.business,
          selectedSurveys: action.payload
        }
      }

    default:
      return state;
  }
}