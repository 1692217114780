import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { Redirect } from 'react-router-dom'

import * as ActionTypes from '../../store'
import SideBar from '../SideBar'
import ViewModal from '../../Utilities/ViewModal/viewModal'
import ViewTextModal from '../../Utilities/ViewModal/viewTextModal'
import FreetextAnswersModal from '../../Utilities/ViewModal/freetextAnswersModal'
import './home.css';
import { confs } from '../../bootstrap/config'
import BarReport from './modules/barReport'
import BarReportQuestions from './modules/barReportQuestions'
import TextEditor from '../../Utilities/TextEditor/texteditor'

class Home extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      activeSidebarTab: 'surveys-tab',
      businessName: '',
      address: '',
      businessID: '',
      viewModalIsOpen: false,
      viewNotes: [],
      viewTextModalIsOpen: false,
      viewNoteText: null,
      selectedSurveys: [],
      surveyDateRange: null,
      barReport: true,
      freetextAnswersModalIsOpen: false,
      freetextAnswersForModal: {}
    }
  }

  componentWillMount() {
    if(this.props.loggedUser !== null) {
      this.getBusinessData(this.props.loggedUser.businessId)
    } else {
      const token = window.sessionStorage.getItem("business-token")

      if(token !== null) {
        const params = {
          token: token
        }
        this.props.authenticateUser(params).then(() => {
          this.getBusinessData(this.props.loggedUser.businessId)
        })
      } else {
        this.props.logout()
      }
    }
  }

  getBusinessData = (businessId) => {
    const params = {id: businessId}
    this.props.getBusiness(params).then(() => {
      if(typeof this.props.business !== 'undefined' && this.props.business !== null) {
        this.setState({
          businessName: this.props.business.businessName,
          address: this.props.business.address,
          businessID: this.props.business.businessID
        }, () => {
          // this.props.getSurveyStatistics(this.props.business._id)
          this.props.getNotifications(params)
        })
      }
    })
  }

  handleDateChange = (start, end) => {
    this.setState({surveyDateRange: {startDate: start, endDate: end}})
    this.getSurveyStatistics(this.props.business._id, {startDate: start, endDate: end}, this.state.selectedSurveys)
  }

  handleTabChange = (tab) => {
    this.setState({activeSidebarTab: tab})
  }

  onBusinessSubmit = (e) => {
    e.preventDefault()
    
    const data = {
      businessName: this.state.businessName,
      businessID: this.state.businessID,
      address: this.state.address,
      _id: this.props.business._id
    }

    this.props.editBusiness(data)
  }

  uploadLogo = (e) => {
    const formData = new FormData()
    formData.append("logo", e.target.files[0], e.target.files[0].name)
    formData.append("businessId", this.props.business._id)
    formData.append("token", window.sessionStorage.getItem("business-token"))
    this.props.uploadLogo(formData)
  }

  onAddBusinessUser = (userData) => {
    userData.businessId = this.props.business._id;
    this.props.addBusinessUser(userData)
  }

  toggleNotes = (notes = []) => {
    this.setState(prevState => ({viewModalIsOpen: !prevState.viewModalIsOpen, viewNotes: notes}))
  }

  toggleNoteTextModal = (text = null) => {
    this.setState(prevState => ({viewTextModalIsOpen: !prevState.viewTextModalIsOpen, viewNoteText: text}))
  }

  calculateColor = (avg, group) => {
      let color = "#ed5652";
      group.feedbacks.map((feedback, y) => {
        if(feedback.range[0] <= avg && feedback.range[1] >= avg) {
          color = feedback.color;
        }
      })
      return color;
  }

  getMostAnswersFeedback(questions) {
    let mostAnswersFeedback = questions.questionOptions[0].feedback;
    let mostAnswersIndex = 0;

    questions.questionOptions.map((option, index) => {
      const answerCount = option.countForStatistics;
      if(answerCount > questions.questionOptions[mostAnswersIndex].countForStatistics) {
        mostAnswersFeedback = option.feedback;
        mostAnswersIndex = index;
      }
    })
    return mostAnswersFeedback;
  }

  getSurveyStatistics = (businessId, dateRange = null, surveyIds = null) => {
    let searchArray = []

    surveyIds.map((id) => {
      searchArray.push({id: id})
    })

    this.props.getSurveyStatistics(businessId, dateRange, searchArray)
  }

  onPrintClick = () => {
    let params = [];

    this.state.selectedSurveys.map((surveyId) => {
        let obj = {
            reportId: surveyId
        }
        return params.push(obj)
    })
    this.props.printReport(params);
  }

  updateSelectedSurveys = (surveys) => {
    this.setState({
      selectedSurveys: surveys
    }, () => {
      this.getSurveyStatistics(this.props.business._id, this.state.surveyDateRange, surveys)
    })
  }

  toggleFreetextAnswersModal = (freetextAnswers = {}) => {
    this.setState(prevState => ({
        freetextAnswersModalIsOpen: !prevState.freetextAnswersModalIsOpen,
        freetextAnswersForModal: freetextAnswers
    }))
  }

  render() {
    const logoUrl = `${confs.host}/api/business/getLogo/${this.props.business._id}`

    return (
      <Container fluid style={{height:"100%"}}>
        <ViewModal isOpen={this.state.viewModalIsOpen} notes={this.state.viewNotes} toggleNoteModal={this.toggleNotes} viewNoteText={this.toggleNoteTextModal} />
        <ViewTextModal isOpen={this.state.viewTextModalIsOpen} text={this.state.viewNoteText} toggleModal={this.toggleNoteTextModal} />

        <FreetextAnswersModal 
          isOpen={this.state.freetextAnswersModalIsOpen}
          toggleModal={this.toggleFreetextAnswersModal}
          freetextAnswers={this.state.freetextAnswersForModal}
        />

        {this.props.business &&
          <Row style={{height: "100%"}}>
              
              <SideBar 
                users={this.props.businessUsers} 
                business={this.props.business}
                addBusinessUser={this.onAddBusinessUser} 
                handleDateChange={this.handleDateChange} 
                handleTabChange={this.handleTabChange} 
                saveSurveyTree={this.props.saveSurveyTree}
                closeSideBar={this.onCloseSideBar}
                updateSelectedSurveys={this.updateSelectedSurveys}
                selectedSurveys={this.state.selectedSurveys}
              />

            <main className={"mapping-main col-md-12"}>
              {this.state.activeSidebarTab == 'surveys-tab' ? (
                this.props.sentSurveys && this.props.sentSurveys.length > 0 ? (
                  this.state.barReport ? (
                      <Col style={{height: '100%', overflow: 'auto'}}>
                          <Row style={{paddingBottom: 50}}>
                              <Col xl="4 offset-xl-8 offset-lg-6 offset-md-4 report-button-wrapper" lg="6" md="8" sm="12">
                                  <div block="true" className={(this.state.barReport && !this.state.barReportQuestions) ? "general-button-report general-button-report-active" : "general-button-report"} onClick={() => this.setState({barReport: true, barReportQuestions: false})}><i className="material-icons">view_comfy</i></div>
                                  <div block="true" className={this.state.barReportQuestions ? "general-button-report general-button-report-active" : "general-button-report"} onClick={() => this.setState({barReportQuestions: true})}><i className="material-icons">sort</i></div>
                                  <div block="true" className={!this.state.barReport ? "general-button-report general-button-report-active" : "general-button-report"} onClick={() => this.setState({barReport: false, barReportQuestions: false})}><i className="material-icons">blur_linear</i></div>
                                  <div block="true" className= "general-button-report" onClick={() => this.onPrintClick()}><i className="material-icons">print</i></div>
                              </Col>
                          </Row>
                          {(this.state.barReport && !this.state.barReportQuestions) && <BarReport sentSurveys={this.props.sentSurveys} />}
                          {this.state.barReportQuestions && <BarReportQuestions sentSurveys={this.props.sentSurveys} />}
                      </Col>
                  ) : (
                      <Col style={{height: '100%', overflow: 'auto'}}>
                          <Row style={{paddingBottom: 50}}>
                              <Col xl="4 offset-xl-8 offset-lg-6 offset-md-4 report-button-wrapper" lg="6" md="6" sm="12">
                                  <div block="true" className={(this.state.barReport && !this.state.barReportQuestions)? "general-button-report general-button-report-active" : "general-button-report"} onClick={() => this.setState({barReport: true, barReportQuestions: false})}><i className="material-icons">view_comfy</i></div>
                                  <div block="true" className={this.state.barReportQuestions ? "general-button-report general-button-report-active" : "general-button-report"} onClick={() => this.setState({barReportQuestions: true, barReport: true})}><i className="material-icons">sort</i></div>
                                  <div block="true" className={!this.state.barReport ? "general-button-report general-button-report-active" : "general-button-report"} onClick={() => this.setState({barReport: false, barReportQuestions: false})}><i className="material-icons">blur_linear</i></div>
                                  <div block="true" className= "general-button-report" onClick={() => this.onPrintClick()}><i className="material-icons">print</i></div>
                              </Col>
                          </Row>
                          {this.props.sentSurveys.map((survey, ssIndex) => {
                              
                              const surveyColor = survey.surveyColor;
                              
                              return typeof survey.surveyTotalStatistics !== 'undefined' && 
                                  <Row className="survey-main-row" key={ssIndex}>
                                      <div className="ball-container">
                                      <div className="large-score-ball" style={{backgroundColor: surveyColor, boxShadow: "0 0 0 2pt " + surveyColor}}>
                                          {survey.surveyTotalStatistics.avg}
                                      </div>
                                      </div>
                                      <Col md={{size: 11}} className="survey-title-container super-border-left" style={{borderColor: surveyColor}}>
                                      <Row className="survey-title-row"> 
                                        <Col md={{size: 12}}>
                                          <Row>
                                            <Col md={{size: 11}}>
                                              <h2>
                                                {survey.title}
                                              </h2>  
                                            </Col>
                                            <Col md={{size: 1}} className="notes-icon-col">
                                              {/* {survey.notes && survey.notes.length > 0 && <i className="material-icons notes-icon" style={{float: "right"}} onClick={() => this.toggleNotes(survey.notes)}>library_books</i>} */}
                                              {typeof survey.freetextAnswers !== 'undefined' && <i className="material-icons float-right cursor-pointer" onClick={() => this.toggleFreetextAnswersModal(survey.freetextAnswers)} style={{fontSize: '2rem'}}>chat_bubble_outline</i>}
                                            </Col>
                                          </Row>
                                          
                                          <hr />
                                          <TextEditor content={survey.text} readOnly={true} onTextChange={() => {return}} />

                                          {(typeof survey.notes !== 'undefined' && survey.notes.length > 0) &&    
                                            <Row className="notes-row">
                                              <Col>
                                                <div style={{width: '89%', float: 'left'}}>
                                                  <h4>Muistiinpanot</h4>
                                                </div>
                                                {/* <div style={{width: '10%', float: 'right'}}>
                                                  <i className="material-icons float-right addNote" onClick={() => this.onNotesCheck({mainId: survey._id})} style={{fontSize: '2rem'}}>notes</i>
                                                </div> */}
                                                <hr style={{clear: 'both'}}/>
                                                {survey.notes.map((note, y) => 
                                                  <p key={y}>{note.notes}</p>
                                                )}
                                              </Col>
                                            </Row>
                                          }

                                        </Col>
                                      </Row>
                                      {survey.questionGroups.map((group, gIndex) => {
                                          
                                          const groupColor = group.groupColor;
                  
                                          return typeof group.groupTotalStatistics !== 'undefined' && 
                                          <Row className="questiongroup-main-row" key={gIndex}>
                                            <div className="ball-container">
                                                <div className="medium-score-ball" style={{backgroundColor: groupColor, boxShadow: "0 0 0 2pt " + groupColor}}>{group.groupTotalStatistics.avg}</div>
                                            </div>
                                            <Col md={{size: 11}} className="questiongroup-title-container super-border-left" style={{borderColor: groupColor}}>
                                              <Row className="questiongroup-title-row">
                                                <Col md={{size: 12}}>
                                                  <Row>
                                                    <Col md={{size: 11}}>
                                                      <h2>
                                                        {group.title}
                                                      </h2>  
                                                    </Col>
                                                    {/* <Col md={{size: 1}} className="notes-icon-col">
                                                      {group.notes && group.notes.length > 0 && <i className="material-icons notes-icon" style={{float: "right"}} onClick={() => this.toggleNotes(group.notes)}>library_books</i>}
                                                    </Col> */}
                                                  </Row>
                                                <hr />
                                                <TextEditor content={group.text} readOnly={true} onTextChange={() => {return}} />
                                                {group.feedbacks && group.feedbacks.length > 0 && group.feedbacks.map((feedback, index) => {
                                                    if(group.groupTotalStatistics.avg >= feedback.range[0] && group.groupTotalStatistics.avg <= feedback.range[1]) {
                                                      return <TextEditor key={index} content={feedback.text} readOnly={true} onTextChange={() => {return}} />
                                                    }
                                                })}

                                                {(typeof group.notes !== 'undefined' && group.notes.length > 0) &&
                                                  <Row className="notes-row">
                                                    <Col>
                                                      <div style={{width: '89%', float: 'left'}}>
                                                        <h4>Muistiinpanot</h4>
                                                      </div>
                                                      {/* <div style={{width: '10%', float: 'right'}}>
                                                        <i className="material-icons float-right addNote" onClick={() => this.onNotesCheck({groupId: group.id})} style={{fontSize: '2rem'}}>notes</i>
                                                      </div> */}
                                                      <hr style={{clear: 'both'}}/>
                                                      {group.notes.map((note, y) => 
                                                        <p key={y}>{note.notes}</p>
                                                      )}
                                                    </Col>
                                                  </Row>
                                                }

                                                </Col>
                                              </Row>
                                              {group.questions.map((question, qIndex) => {
                                                if(question.answerStatistics && (typeof question.showInReport === 'undefined' || question.showInReport === true)) {
                                                  const questionColor = this.calculateColor(question.answerStatistics.avg, group);
                                                  const maxAnswers = question.questionOptions.reduce((max, option) => max && max > option.countForStatistics ? max : option.countForStatistics, null)

                                                  return (
                                                    <Row className="question-main-row" key={qIndex}>
                                                      <div className="ball-container">
                                                        <div className="small-score-ball" style={{backgroundColor: questionColor, boxShadow: "0 0 0 2pt " + questionColor}}>{question.answerStatistics.avg}</div>
                                                      </div>
                                                      <Col md={{size: 11}} className={"question-title-container" + (group.questions[qIndex + 1] ? " super-border-left" : "")} style={{borderColor: questionColor}}>
                                                        <Row className="question-title-row">
                                                          <Col md={{size: 12}}>
                                                            <div style={{width: '89%', float: 'left'}}>
                                                              <h4>
                                                                {question.text} 
                                                              </h4>
                                                            </div>
                                                            {/* <Col md={{size: 1}} className="notes-icon-col">
                                                              {question.notes && question.notes.length > 0 && <i className="material-icons notes-icon" style={{float: "right"}} onClick={() => this.toggleNotes(question.notes)}>library_books</i>}
                                                            </Col> */}
                                                            <hr style={{clear: 'both'}}/>
                                                            
                                                            <TextEditor content={this.getMostAnswersFeedback(question)} onTextChange={() => {return}} readOnly={true} />

                                                            {question.analytics && question.analytics.length > 0 && question.analytics.map((anal, index) => {
                                                              if(typeof anal.linkText !== 'undefined' && typeof anal.linkURL !== 'undefined') {
                                                                if(question.answerStatistics.avg >= anal.range[0] && question.answerStatistics.avg <= anal.range[1]) {
                                                                  return <div><a key={index} target="_blank" href={anal.linkURL} style={{marginTop: "2em"}}>{anal.linkText}</a></div>
                                                                }
                                                              }
                                                            })}
                                                            
                                                            {question.questionOptions.map((option, index) => {
                                                              const totalAnswers = question.answerStatistics.count;
                                                              const answerCount = option.countForStatistics;
                                                              const percentage = answerCount !== 0 ? Math.round(answerCount / totalAnswers * 100) : 0;
                                                              
                                                              
                                                              return <div key={index} title={option.text} className={"small-analysis-ball" + (answerCount === maxAnswers ? " largest-score-analysis-ball" : "")} style={{backgroundColor: option.color, boxShadow: "0 0 0 2pt " + option.color}}>{percentage}%</div>
                                                            })}
                                                            
                                                            {question.analytics && question.analytics.length > 0 && question.analytics.map((anal, index) => {
                                                              if(question.answerStatistics.avg >= anal.range[0] && question.answerStatistics.avg <= anal.range[1]) {
                                                                return <TextEditor key={index} content={anal.text} onTextChange={() => {return}} readOnly={true} additionalClass="answer-analytic-text" />
                                                              // return <p key={index} style={{marginTop: "2em"}}>{anal.text}</p>
                                                              }
                                                            })}

                                                            {(typeof question.notes !== 'undefined' && question.notes.length > 0) &&
                                                              <Row className="notes-row">
                                                                <Col>
                                                                <div style={{width: '89%', float: 'left'}}>
                                                                  <h4>Muistiinpanot</h4>
                                                                </div>
                                                                {/* <div style={{width: '10%', float: 'right'}}>
                                                                  <i className="material-icons float-right addNote" onClick={() => this.onNotesCheck({questionId: question.id})} style={{fontSize: '2rem'}}>notes</i>
                                                                </div> */}
                                                                <hr style={{clear: 'both'}}/>
                                                                  {question.notes.map((note, y) => 
                                                                      <p key={y}>{note.notes}</p>
                                                                  )}
                                                                </Col>
                                                              </Row>
                                                            }
                                                          </Col>
                                                        </Row>
                                                      </Col>
                                                    </Row>
                                                  )
                                                }
                                              })}
                                            </Col>  
                                          </Row>
                                      })}
                                      </Col>
                                      
                                  </Row>
                              })}
                          </Col>
                  )
                ) : (
                  <div className="dashboard-row">
                    <Col md={{size: 10}} className="dashboard-container">
                      <Row>
                        <Col md={{size: 12}} className="dashboard-box-background"></Col>
                      </Row>
                      <Row>
                        <Col md={{size: 12}} className="dashboard-box-content">
                          <h3>Tervetuloa!</h3>
                          <p>Palvelu avasi ovensa 1.2.2018 beta vaiheeseen. 
                            Qartoitus.com tarkoituksena on helpottaa ja nopeuttaa tiedon keruuta, 
                            sekä luoda kartoitukseen vastaavalle positiivinen ja uniikki kokemus. 
                            Palvelun olessa Beta vaiheessa olemme entistäkin kiinnostuneempia kuulemaan 
                            palautetta ja muokkaamaan palvelua luomaan vieläkin paremman käyttäjäkokemuksen. 
                            Voit lähettää meille palautetta osoitteeseen tuki@qibbie.com.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                )
              ) : (
                <Container style={{marginTop: "1em"}}>
                  <Row>
                    <Col md={{size: 12}}>
                      <h1>Yrityksen tietojen muokkaus</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{size: 12}}>
                      <Form onSubmit={this.onBusinessSubmit} ref={el => (this.businessForm = el)}>
                        
                        <FormGroup>
                          <Label>Nimi</Label>
                          <Input className="business-input" onChange={(e) => this.setState({businessName: e.target.value})} type="text" name="name" value={this.state.businessName} />
                        </FormGroup>

                        <FormGroup>
                          <Label>Osoite</Label>
                          <Input className="business-input" onChange={(e) => this.setState({address: e.target.value})} type="text" name="address" value={this.state.address} />
                        </FormGroup>

                        <FormGroup>
                          <Label>Y-tunnus</Label>
                          <Input className="business-input" onChange={(e) => this.setState({businessID: e.target.value})} type="text" name="businessID" value={this.state.businessID} />
                        </FormGroup>

                        <FormGroup className="logo-group">
                          <Label>Logo
                          <div className="logo-container logo-input cursor-pointer" style={ this.props.business.logo && {backgroundImage: `url(${logoUrl})`}}>
                            <Input onChange={this.uploadLogo} type="file" name="logo" hidden />
                          </div>
                          </Label>
                        </FormGroup>

                        <Button type="submit" style={{width: "100%", marginTop: "1em"}}>Tallenna</Button>

                      </Form>
                    </Col>
                  </Row>
                </Container>
              )} 
            </main>
          </Row>
        }
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNotifications: (data) => dispatch(ActionTypes.getNotifications(data)),
    getSurveyStatistics: (businessId, date = null, surveyIds = null) => dispatch(ActionTypes.getSurveyStatistics(businessId, date, surveyIds)),
    getBusiness: (data) => dispatch(ActionTypes.getBusiness(data)),
    editBusiness: (data) => dispatch(ActionTypes.editBusiness(data)),
    uploadLogo: (data) => dispatch(ActionTypes.uploadLogo(data)),
    addBusinessUser: (data) => dispatch(ActionTypes.addBusinessUser(data)),
    saveSurveyTree: (businessId, surveyTree) => dispatch(ActionTypes.reorderSurveytree(businessId, surveyTree)),
    authenticateUser: (params) => dispatch(ActionTypes.authenticateUser(params)),
    printReport: (params) => dispatch(ActionTypes.printReport(params)),
    logout: () => dispatch(ActionTypes.doLogout())
  }
}

const mapStateToProps = state => {
  return {
    sentSurveys: state.surveys.sentSurveys,
    notifications: state.notifications,
    business: state.business.business,
    businessUsers: state.business.businessUsers,
    loggedUser: state.login.loggedUser
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)