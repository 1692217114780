
let url
let pathrul
let host
let frontpageUrl

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  url = "http://192.168.1.109:9001/api/business/";
  pathrul = "";
  host = "http://localhost:9001"
  frontpageUrl = "http://localhost:3008/"
 
} else {
  url = "https://business.qartoitus.com/api/business/";
  host = "https://business.qartoitus.com"
  frontpageUrl = "https://beta.qartoitus.com/"
	pathrul = "";
}
export let confs = {
  url: url,
  pathurl: pathrul,
  host: host,
  frontpageUrl: frontpageUrl
};