import * as ActionTypes from '../actions/surveyActions/actionTypes';

const initialState = {
  surveyTree: [],
  sentSurveys: null
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ActionTypes.GET_SURVEYS:
      return {
        ...state,
        surveyTree: action.payload
      }
    case ActionTypes.GET_STATISTICS:
      return {
        ...state,
        sentSurveys: [
          ...action.payload
        ]
      }
    default:
      return state;
  }
}