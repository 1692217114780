import React from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import { connect } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Treehead from './treehead'
import * as ActionTypes from '../../../store'

class Treeview extends React.Component {
  
  constructor() {
    super()

    this.state = {
      selectedSurveys: []
    }
  }

  onDragEnd = result => {
    const { destination, source, draggableId, type } = result
    //no changes if there is no destination, or if the dragged items position hasn't changed
    if(!destination) { return }
    if(destination.droppableId === source.droppableId &&
       destination.index === source.index
      ) {
        return
      }

    let newSurveyTree = [];
    if(type === 'header') {
      const newIds = Array.from(this.props.business.surveyTree)
      const movedObj = newIds[source.index]
      newIds.splice(source.index, 1)
      newIds.splice(destination.index, 0, movedObj)

      newSurveyTree = [
        ...newIds
      ]
    } else {
      const newData = this.props.business.surveyTree

      const movedObj = newData[source.droppableId].surveys[source.index]

      newData[source.droppableId].surveys.splice(source.index, 1)
      newData[destination.droppableId].surveys.splice(destination.index, 0, movedObj)

      newSurveyTree = [
        ...newData
      ]
    }
    this.props.reorderSurveys(this.props.business._id, newSurveyTree)
  }

  render() {
    
    return (
      <Container fluid className="tree-container">
        <Row>
          <Col md={{size: 12}}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable-container" type="header">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={snapshot.isDraggingOver ? 'dragging-over' : ''}
                    {...provided.droppableProps}
                  >
                  {typeof this.props.business.surveyTree != 'undefined' && this.props.business.surveyTree.length > 0 && this.props.business.surveyTree.map((obj, index) => {
                    return <Treehead 
                      key={index} 
                      isDragDisabled={false} 
                      id={index.toString()} 
                      class={index === 0 ? "first-header" : ""} 
                      surveyTree={obj}
                      index={index} 
                      onTreeHeadClick={this.props.onTreeHeadClick} 
                      onTreeSurveyClick={this.props.onTreeSurveyClick}
                      selectedSurveys={this.props.selectedSurveys}
                      />
                  })}
                  {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    business: state.business.business
  }
}

const mapDispatchToProps = dispatch => {
  return {
    reorderSurveys: (business, surveyTree) => dispatch(ActionTypes.reorderSurveytree(business, surveyTree))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Treeview)