import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

export default class Treenode extends React.Component {

  render() {
    const selected = this.props.selectedSurveys && this.props.selectedSurveys.indexOf(this.props.id) !== -1 ? true : false
    
    return (
      <Draggable index={this.props.index} draggableId={this.props.id} type="node">
        {(provided, snapshot) => {
          const style = {
            // color: snapshot.isDragging ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,1)',
            ...provided.draggableProps.style  
          }
          return (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
              ref={provided.innerRef}
              data-id={this.props.id}
              onClick={() => this.props.onTreeSurveyClick(this.props)}
              className={"tree-node" + (selected ? " tree-node-active" : "")}
            >
              {this.props.title}
              {/* {selected && <i className='material-icons survey-selected-mark'>check</i>} */}
            </div>
        )}}
      </Draggable>
    )
  }
}
