import * as ActionTypes from './actionTypes'
import { confs } from '../../../bootstrap/config'

const doLogin = params => {
  return {
    type: ActionTypes.LOGIN,
    payload: params
  }
}

export const doLogout = () => dispatch => {
  dispatch({
    type: ActionTypes.LOGOUT
  })
  window.sessionStorage.removeItem('business-token')
  window.location.href = confs.frontpageUrl + 'login'
}

export const checkLogin = (params) => dispatch => {
  return fetch(confs.url + 'checkLogin', {
    method: 'POST',
    body: JSON.stringify(params)
  })
  .then(res => res.json())
  .then(data => {
    if(data.token) {
      let token = data.token;
      window.sessionStorage.setItem("business-token", token)
      dispatch(doLogin(data))
    } else {
      doLogout()
    }
  })
}

export const authenticateUser = (params) => dispatch => {
  return fetch(confs.url + 'authenticateUser', {
    method: 'POST',
    body: JSON.stringify(params)
  })
  .then(res => res.json())
  .then(data => {
    if(data !== null) {
      dispatch(doLogin(data))
    } else {
      doLogout()
    }
  })
}
